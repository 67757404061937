import { Component,ElementRef,Input,OnInit,Output,ViewChild } from '@angular/core';
import { UserDto } from 'src/app/entity/user-dto';
import { UserType } from 'src/app/constant/user-type.enum.enum';
import { EventEmitter } from '@angular/core';
import { UserService } from 'src/app/service/user.service';
import { GeneralUtil } from 'src/app/util/general-util';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { State } from 'src/app/entity/state';
import { StateService } from 'src/app/service/state.service';
import { AuthService } from 'src/app/service/auth/auth.service';

declare function successToast(msg): any;

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  public newUser: UserDto = new UserDto();
  @Output() payLoad = new EventEmitter<string>();
  @ViewChild('close') closeButton: ElementRef;
  public queryData = '';
  public userRole = '';
  public states: State[];
  public statesCentral: State[];
  public showPreloader = false;
  public showCentral = false;
  public courierRole = ''

  constructor(private userService: UserService,private route: ActivatedRoute,private stateService: StateService,
    public authService: AuthService) { }
  ngOnChanges() {
  }

  ngOnInit(): void {
    this.getAllStates();
    this.getStatesForCentral();
    this.route.queryParams.subscribe((data) => {
      this.queryData = data.page;
      if (this.queryData == 'super_admin') {
        this.userRole = UserType.USER;
      } else if (this.queryData == 'logistic_admin') {
        this.userRole = UserType.COURIER_ADMIN;
      } else if (this.queryData == 'central_admin') {
        this.userRole = UserType.CENTRAL_ADMIN;
      } else {
        this.userRole = UserType.COURIER_AGENT;
      }
    })
  }



  createUser(user: NgForm) {
    let myUser: any = Object.assign({},this.newUser);
    myUser.role = this.userRole;
    this.showPreloader = true;
    this.userService.registerUser(myUser)
      .subscribe((data) => {
       successToast('User Created Successfully');
        user.reset();
        this.showPreloader = false;
        this.closeButton.nativeElement.click();
        this.payLoad.emit();
      }
        ,(error) => {
          this.showPreloader = false;
          this.closeButton.nativeElement.click();
          this.errorHandler(error);
        })
  }


  getStatesForCentral() {
    this.stateService.getStates().subscribe((data) => {
      this.statesCentral = [];
      if (data.length > 0) {
        const allStates = data;
        allStates.forEach((p) => {
          const oneState = new State();
          oneState.mapToState(p);
          this.statesCentral.push(oneState);
        });
        this.showPreloader = false;

          this.statesCentral = this.statesCentral.filter(s => s.name.includes('Abuja'))

        return this.statesCentral;
      } else {
        this.showPreloader = false;
        return this.statesCentral;
      }
    },(error) => {
      this.errorHandler(error)
    })
  }

  getAllStates() {
    this.stateService.getStates().subscribe((data) => {
      this.states = [];
      if (data.length > 0) {
        const allStates = data;
        allStates.forEach((p) => {
          const oneState = new State();
          oneState.mapToState(p);
          this.states.push(oneState);
        });
        this.showPreloader = false;
         return this.states;
      } else {
        this.showPreloader = false;
        return this.states;
      }
    },(error) => {
      this.errorHandler(error)
    })
  }

  // onUserAdded(event) {  }
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }

}
