import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.initReload();
  }

  initReload() {
    if (!localStorage.getItem('load')) {
      localStorage.setItem('load', 'no load');
      location.reload();
    } else {
      localStorage.removeItem('load');
    }
  }

}
