import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { TokenInterceptorService } from "./service/auth/token-interceptor.service";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SideMenuComponent } from "./tags/side-menu/side-menu.component";
import { HeaderComponent } from "./tags/header/header.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { LoginComponent } from "./components/login/login.component";
import { AuthGuard } from "./service/auth/auth.guard";
import { AuthService } from "./service/auth/auth.service";
import { SharedModule } from "./shared/shared.module";
import { ChartsModule } from 'ng2-charts';
import { ReportsComponent } from './components/reports/reports.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { HomeComponent } from "./components/home/home.component";
import { ProfileComponent } from './components/profile/profile.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { AuditTrailComponent } from './components/audit-trail/audit-trail.component';
import { BatchDetailsComponent } from './components/batch-details/batch-details.component';
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";

@NgModule({
  declarations: [
    AppComponent,
    SideMenuComponent,
    HeaderComponent,
    DashboardComponent,
    LoginComponent,
    ReportsComponent,
    UnauthorizedComponent,
    HomeComponent,
    ProfileComponent,
    ForgotPasswordComponent,
    AuditTrailComponent,
    BatchDetailsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ChartsModule,
    HttpClientModule,
    SharedModule,
    NgbModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  exports: [],
  providers: [
    AuthGuard,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
