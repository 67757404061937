import { GeneralUtil } from '../util/general-util';

export class LocationLiteDto {
  public lat: number;
  public lng: number;
  public timestamp: string;

  public mapToLocationLiteDto(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.lng = Number(data.longitude);
      this.lat = Number(data.latitude);
      this.timestamp = data.timestamp;
    }
  }
}
