import { Facility } from './facility';
import { GeneralUtil } from '../util/general-util';
import { OriginFacility } from './origin-facility';

export class PickupRequest {
  public batchId: string;
  public requestId: number;
  public origin = new Facility();
  public officerName: string;
  public officerDesignation: string;
  public destination = new Facility();
  public dateTimeRequest: string;
  public deliveryOfficer: string;
  public deliveryStatus: string;
  public timeDeliveryAssigned: string;
  public timeDelivered: string;
  public lastKnownLongitude: number;
  public lastKnownLatitude: number;
  public delivered: boolean;
  public destinationToAirport: boolean;
  public flightDepartureTime: string;
  public flightArrivalTime: string;
  public pickUpTime: string;
  public handover: boolean;
  public numberOfConsignment: number;
  public deliveryOfficerPhoneNumber: string;
  public originalOriginFacility = new OriginFacility()
  public finalDestination = new Facility();
  public initiatedBy: string;
  public assignedBy: string;
  public timeDeliveryAssignedAgo: string;
  public ago: string;
  public timeTripStartedAgo: string;
  public timeDeliveredAgo: string;
  public deliveryStartTime: string;

  public mapToPickupRequest(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.batchId = data.batchId;
      this.dateTimeRequest = data.dateTimeRequest;
      this.delivered = data.delivered;
      this.deliveryOfficer = data.deliveryOfficer;
      this.deliveryStatus = data.deliveryStatus;
      this.destination.mapToFacility(data.destination);
      this.destinationToAirport = data.destinationToAirport;
      this.flightArrivalTime = GeneralUtil.convertIonDateTimeToDateTimeString(data.flightArrivalTime);
      this.flightDepartureTime = GeneralUtil.convertIonDateTimeToDateTimeString(data.flightDepartureTime);
      this.lastKnownLatitude = data.lastKnownLatitude;
      this.lastKnownLongitude = data.lastKnownLongitude;
      this.officerDesignation = data.officerDesignation;
      this.officerName = data.officerName;
      this.origin.mapToFacility(data.origin);
      this.pickUpTime = data.pickUpTime;
      this.requestId = data.requestId;
      this.originalOriginFacility.mapToOriginFacility(data.originalOriginFacility);
      this.finalDestination.mapToFacility(data.finalDestination)
      this.timeDeliveryAssigned =
        GeneralUtil.convertIonDateTimeToDateTimeString(data.timeDeliveryAssigned);
      this.timeDelivered = GeneralUtil.convertIonDateTimeToDateTimeString(data.timeDelivered);
      this.handover = data.handover;
      this.numberOfConsignment = data.numberOfConsignment;
      this.deliveryOfficerPhoneNumber = data.deliveryOfficerPhoneNumber;
      this.initiatedBy = data.initiatedBy;
      this.assignedBy = data.assignedBy;
      this.timeDeliveryAssignedAgo = data.timeDeliveryAssignedAgo;
      this.ago = data.ago;
      this.timeTripStartedAgo = data.timeTripStartedAgo;
      this.timeDeliveredAgo = data.timeDeliveredAgo;
      this.deliveryStartTime =  GeneralUtil.convertIonDateTimeToDateTimeString(data.deliveryStartTime);
    }
  }
}
