import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CreateUserComponent } from '../components/create-user/create-user.component';
import { PickupDetailsComponent } from '../components/pickup-details/pickup-details.component';
import { FooterComponent } from '../tags/footer/footer.component';
import { MapComponent } from './../components/map/map.component';

@NgModule({
  imports: [CommonModule,FormsModule,NgbModule],
  declarations: [FooterComponent,MapComponent,PickupDetailsComponent,CreateUserComponent],
  exports: [FooterComponent,MapComponent,PickupDetailsComponent,CreateUserComponent,NgbModule],
})
export class SharedModule { }
