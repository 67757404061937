import { GeneralUtil } from '../util/general-util';
import { Role } from './role-dto';
import { State } from './state';

export class UserDto {
 public id: number;
 public status: string;
 public firstName: string;
 public lastName: string;
 public phoneNumber: string;
 public email: string;
 public busy: string;
 public stateId: number;
 public role = new Role();
 public state = new State();

 public mapToUser(data: any) {
  if (GeneralUtil.isValidJSON(data)) {
   this.id = data.id;
   this.status = data.status;
   this.firstName = data.firstName;
   this.lastName = data.lastName;
   this.phoneNumber = data.phoneNumber;
   this.email = data.email;
   this.role.mapToRole(data.roleDto)
   this.state.mapToState(data.stateDto)
  }
 }
}
