import { PickupRequest } from 'src/app/entity/pickup-request';
import { DeliveriesService } from 'src/app/service/deliveries.service';
import { Component, OnInit } from '@angular/core';
import { GeneralEnum } from 'src/app/constant/general-enum.enum';
import { PaginationDataDto } from 'src/app/dto/pagination-data-dto';
import { GeneralUtil } from 'src/app/util/general-util';

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.scss']
})
export class AuditTrailComponent implements OnInit {
  public showPreloader = false;
  public showLoader = false;
  public paginationData = new PaginationDataDto();
  public batches: PickupRequest[] = [];
  public batchStatus: string = 'ALL';
  public handoffs: PickupRequest[] = [];
  public previousBatchTrip = new PickupRequest();
  public singleBatch: PickupRequest;
  public currentPage : number ;
  public limit : number;
  public batchId : string;

  constructor(public requestService: DeliveriesService) { }

  ngOnInit(): void {
    this.initializePaginationData()
    this.getBatchesForAudit();
  }


  /*********PAGINATION FUNCTIONS***************/
  initializePaginationData() {
    this.paginationData.currentPage = 1;
    this.paginationData.defaultLimit = GeneralEnum.paginationLimit;
    this.paginationData.totalSize = 100;
  }


  getBatchesForAudit() {
    // this.initializePaginationData()
    this.showPreloader = true;
    if (this.batchStatus == 'HANDOFF') {
      this.getHandOffs()
      return
    }
    const defaultLimit = this.paginationData.defaultLimit;
    const currentPage = this.paginationData.currentPage;
    this.limit = this.paginationData.defaultLimit;
    this.currentPage = this.paginationData.currentPage;
    console.log(this.currentPage, this.limit);

    this.requestService.getAllPickUpRequests(this.currentPage -1, this.limit, this.batchStatus, this.batchId)
      .subscribe(
        (data) => {
          this.showPreloader = false;
          this.batches = [];
           if (data.total > 0) {
            this.paginationData.totalSize = data.total;
            const pickupAllRequestArray = data.entities;
            pickupAllRequestArray.forEach((p) => {
              const pickRequest = new PickupRequest();
              pickRequest.mapToPickupRequest(p);
              this.batches.push(pickRequest);
            });

          }
          

          return this.batches;
        },
        (error) => {
          this.showPreloader = false;
          this.errorHandler(error);
        }
      );
  }

  getOnePickupDetail(pickupRequest) {
    this.singleBatch = pickupRequest;
    this.getPreviousBatchTrip(this.singleBatch.batchId)
  }



  getHandOffs() {
    this.showPreloader = true;
    const defaultLimit = this.paginationData.defaultLimit;
    const currentPage = this.paginationData.currentPage;
    this.requestService
      .getAllHandOffs((currentPage - 1), defaultLimit)
      .subscribe(
        (data) => {
          this.showPreloader = false;
          this.batches = [];
          if (data.total > 0) {
            this.paginationData.totalSize = data.total;
            const pickupAllRequestArray = data.entities;
            pickupAllRequestArray.forEach((p) => {
              const pickRequest = new PickupRequest();
              pickRequest.mapToPickupRequest(p);
              this.batches.push(pickRequest);
            });
          }

          return this.batches
        },
        (error) => {
          this.showPreloader = false;
          this.errorHandler(error);
        }
      );

  }


  getPreviousBatchTrip(batchId) {
    this.showLoader = true
    this.requestService.getPreviousTrip(batchId)
      .subscribe(data => {
        this.showLoader = false;
        this.previousBatchTrip.mapToPickupRequest(data)
        return this.previousBatchTrip;
      },
        error1 => {
          this.showLoader = false
          this.errorHandler(error1);
        });
  }

  filterBatches(){
    this.initializePaginationData();
    this.getBatchesForAudit();
  }


  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }

}
