import { HttpClient,HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { GeneralEnum } from "../constant/general-enum.enum";

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(public httpClient: HttpClient) { }

  public getReports(page,size,batchIdOrFacilityCode?,requestType?) {
    let url;
    if (batchIdOrFacilityCode) {
      if (requestType == 'batchId') {
        url = GeneralEnum.baseUrl + `/auth/web/batch/report?batchId=${batchIdOrFacilityCode}&page=` + page + '&size=' + size;

      } else {
        url = GeneralEnum.baseUrl + `/auth/web/batch/report?locationCode=${batchIdOrFacilityCode}&page=` + page + '&size=' + size;

      }
    } else {
      url = GeneralEnum.baseUrl + '/auth/web/batch/report?page=' + page + '&size=' + size;
    }

    return this.httpClient.get<any>(url).pipe(catchError(this.errorHandler));
  }

  // send report to email
  public getMasterReport() {
    const url = GeneralEnum.baseUrl + '/auth/master/report?';
    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  // Error Handler
  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
