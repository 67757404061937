import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { LocationLiteDto } from 'src/app/entity/location-lite-dto';
import { PickupRequest } from 'src/app/entity/pickup-request';
import {} from 'googlemaps';



@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnChanges {
  @ViewChild('Map') mapElement: ElementRef;
  @Input () pickupRequest: PickupRequest;

  public userLocation = new LocationLiteDto();
  public originLocation: any;
  public locations = [];
  public map: google.maps.Map;
  public markers: google.maps.Marker[] = [];
  public markerIcons = ['http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
    'http://maps.google.com/mapfiles/ms/icons/green-dot.png', '../../../assets/media/avatars/delivery.png'];
  public ds: google.maps.DirectionsService;
  public dr: google.maps.DirectionsRenderer;
  public options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    scrollwheel: true,
    disableDefaultUI: true,
    disableDoubleClickZoom: true,
    zoom: 6,
  };
  mapOptions: any;
  markerOptions: any = {
    position: null,
    map: null,
    title: null,
    color: 'yellow',
  };

  constructor() {
    this.initializeApp();
  }

  ngOnInit(): void {

    this.ds = new google.maps.DirectionsService();
    this.dr = new google.maps.DirectionsRenderer({
    map: null,
    suppressMarkers: true,
  });

  }


  ngOnChanges(changes: SimpleChanges): void {
    this.pickupRequest = changes.pickupRequest.currentValue;

    if (changes.pickupRequest.firstChange === false) {
      const originatingLocation = new LocationLiteDto();
      originatingLocation.lat = Number((this.pickupRequest.origin.latitude).toFixed(6));
      originatingLocation.lng = Number((this.pickupRequest.destination.longitude).toFixed(6));
      this.originLocation = new google.maps.LatLng(originatingLocation.lat, originatingLocation.lng);

      // load map
      this.loadMap();

    }

  }

  initializeApp() {
    this.setUserLocation();
  }


  loadMap() {
    /*Map options*/
    this.mapOptions = {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      label: true,
      center: this.originLocation,
      zoom: 15,
      mapTypeControl: true,
      fullscreenControl: true,
    };


      this.map = new google.maps.Map(
        this.mapElement.nativeElement,
        this.mapOptions
      );
      this.markerOptions.map = this.map;


    this.setLocation();
    setTimeout(() => {
      this.setMapMarkers(this.locations);
    }, 1000);
  }


  setLocation() {
    this.locations = [];

    // add originating facility location
    const originatingLocation = new LocationLiteDto();
    originatingLocation.lat = Number((this.pickupRequest.origin.latitude).toFixed(6));
    originatingLocation.lng = Number((this.pickupRequest.origin.longitude).toFixed(6));
    const originLatLng = new google.maps.LatLng(originatingLocation.lat, originatingLocation.lng);
    this.locations.push(originLatLng);

    // add destination lab location
    const destinationLocation = new LocationLiteDto();
    destinationLocation.lat = Number((this.pickupRequest.destination.latitude).toFixed(6));
    destinationLocation.lng = Number((this.pickupRequest.destination.longitude).toFixed(6));
    const destinationLatLng = new google.maps.LatLng(destinationLocation.lat, destinationLocation.lng);
    this.locations.push(destinationLatLng);

    // add user location
    const userLocation = new LocationLiteDto();
    userLocation.lat = this.pickupRequest.lastKnownLatitude;
    userLocation.lng = this.pickupRequest.lastKnownLongitude;
    const userLatLng = new google.maps.LatLng(userLocation.lat, userLocation.lng);
    this.locations.push(userLatLng);
  }


  setMapMarkers(locations) {
    this.markers = [];

    setTimeout(() => {

      for (let i = 0; i < locations.length; i++) {
        const marker = new google.maps.Marker({
          position: locations[i],
          animation: google.maps.Animation.BOUNCE,
          icon: {
            url: this.markerIcons[i],
            labelOrigin: new google.maps.Point(11, 40),
          },
          map: this.map
        });

        this.markers.push(marker);
      }
      this.setRoutePolyline(locations[0], locations[1]);
    }, 1000);
  }

  reloadMarkers() {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }

    this.markers = [];

    this.setMapMarkers(this.locations);
  }


  setRoutePolyline(origination, dest) {
    const request = {
      origin: origination,
      destination: dest,
      travelMode: google.maps.TravelMode.DRIVING,
    };
    this.ds.route(request, (response, status) => {
      this.dr.setOptions({
        suppressPolylines: false,
        map: this.map,
      });
      if (status === google.maps.DirectionsStatus.OK) {
        this.dr.setDirections(response);
      }
    });
  }

  setUserLocation() {
    setInterval(() => {
      this.setLocation();
      this.reloadMarkers();
    }, 600000) // 10 mins interval

  }


}
