import { Injectable } from '@angular/core';
import { CanActivate,Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
    private authService: AuthService) { }

  canActivate() {
    if (this.authService.isLoggedIn()) {
      if (this.authService.getUserDetailItem('TRACKER-WEB-USER-DETAILS-ROLE') == 'USER') {
        return true;
      } else {
        this.router.navigate(['/unauthorized']);
        this.authService.resetUserDetails();
        return false;
      }
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

}
