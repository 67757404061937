import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PickupRequest } from 'src/app/entity/pickup-request';
import { DeliveriesService } from 'src/app/service/deliveries.service';
import { GeneralUtil } from 'src/app/util/general-util';

@Component({
  selector: 'app-pickup-details',
  templateUrl: './pickup-details.component.html',
  styleUrls: ['./pickup-details.component.scss']
})
export class PickupDetailsComponent implements OnInit, OnChanges {
  @Input () pickupRequest: PickupRequest;
  public singleBatch = new PickupRequest();
  public id: string;

  constructor(private requestService: DeliveriesService) { }

  ngOnInit(): void {
  }

  // to watch changes of @Input value
  ngOnChanges(changes: SimpleChanges): void {
    this.pickupRequest = changes.pickupRequest.currentValue;
     this.id = this.pickupRequest.batchId;
     this.getOnePickupRequest(this.id)

  }
  getOnePickupRequest(batchId){
    this.requestService.getPreviousTrip(batchId)
    .subscribe(data => {
      this.singleBatch.mapToPickupRequest(data)
      return this.singleBatch;
    },
      error1 => {
        this.errorHandler(error1);
      });
  }

  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }

}
