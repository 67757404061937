export class ForgotPasswordDto {
    public newPassword: string;
    public confirmPassword: string; // transient
    public token: string;
  }

  export class ResetPasswordDto {
    public callBackUrl: string;
    public email: string;
  }

  export class UpdatePasswordDto {
    public newPassword: string;
    public oldPassword: string;
  }