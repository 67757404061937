import { Component, OnInit } from '@angular/core';
import { DashboardService } from './../../service/dashboard.service';
import { AdminDashboardStatsDto } from './../../entity/admin-dashboard-stat';
import { GeneralUtil } from 'src/app/util/general-util';
import { DeliveryByState } from './../../entity/delivery-state';
import { ChartType, ChartOptions } from 'chart.js';
import {
  SingleDataSet,
  Label,
  monkeyPatchChartJsLegend,
  monkeyPatchChartJsTooltip,
} from 'ng2-charts';
import { DeliveriesService } from 'src/app/service/deliveries.service';
import { PickupRequest } from 'src/app/entity/pickup-request';
import { PickupStatus } from 'src/app/constant/pickup-status.enum';
import { State } from 'src/app/entity/state';
import { StateService } from 'src/app/service/state.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public greeting: string;
  public showPreloader = false;
  public ongoingRequests: PickupRequest[] = [];
  public pickupStatus = PickupStatus;
  public dashboardStats = new AdminDashboardStatsDto();
  public deliveryByState = new DeliveryByState();
  public states: State[] = [];
  public requestPreloader = false;

  // Bar Chart Setup
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
             callback: function (val) {
               return Number.isInteger(val) ? val : null;
           },
            beginAtZero: true
          }
        }
      ]
    }
  };

  public barChartLabels = [
    'Abuja',
    'Lagos',
    'Enugu',
    'Kaduna',
    'Bauchi',
    'Edo',

  ];
  public barChartType = 'bar';
  public barChartLegend = true;

  public barChartData = [{ data: [], label: 'Delivery by states' }];

  constructor(
    public dashboardService: DashboardService,
    private requestService: DeliveriesService,
    public stateService: StateService,
    public router: Router,
  ) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit(): void {
    this.greetingUser();
    this.getStates();
    this.getDashboardStats();
    this.getDeliveryByStateStats();
    this.getOngoingPickUpRequest();
  }


  routeToInitiated(){
    this.router.navigate(['/deliveries'],{ queryParams: { status: 'initiated' } } );
  }

  routeToOngoing(){
    this.router.navigate(['/deliveries'],{ queryParams: { status: 'ongoing' } } );
  }

  routeToHandOff(){
    this.router.navigate(['/deliveries'],{ queryParams: { status: 'handoff' } } );
  }

  routeToDelivered(){
    this.router.navigate(['/deliveries'],{ queryParams: { status: 'delivered' } } );
  }



  getStates() {
    this.states = [];

    this.stateService.getStates()
      .subscribe((data) => {
        if (data) {
          const stateArray = data;
          stateArray.forEach((s) => {
            const state = new State();
            state.mapToState(s);
            this.states.push(state);
          });
          return this.states;
        }
    });
  }

  greetingUser() {
    const time = new Date().getHours();
    if (time < 12) {
      this.greeting = 'Good morning!';
    } else if (time < 18) {
      this.greeting = 'Good afternoon!';
    } else {
      this.greeting = 'Good evening!';
    }
  }

  getDashboardStats() {
    this.showPreloader = true;
    this.dashboardService.getDashboardStats()
      .subscribe((data) => {

        this.showPreloader = false;
        this.dashboardStats.mapToAdminStats(data);
        return this.dashboardStats;
      },
      (error) => {
        this.showPreloader = false;
        this.errorHandler(error);
      }
    );
  }

  getDeliveryByStateStats() {
    this.dashboardService.getDeliveryByStateStats().subscribe(
      (data) => {
     this.showPreloader = false;
        this.deliveryByState.mapToDelivery(data);
        this.barChartData = [
          {
            data: [
              this.deliveryByState.abuja,
              this.deliveryByState.lagos,
              this.deliveryByState.enugu,
              this.deliveryByState.kaduna,
              this.deliveryByState.bauchi,
              this.deliveryByState.edo,

            ],
            label: 'Delivery by states',
          },
        ];
      },
      (error) => {
        this.errorHandler(error);
      }
    );
  }

  getOngoingPickUpRequest() {
    this.requestPreloader = true
    this.requestService
      .getAllPickUpRequests(0, 5, this.pickupStatus.EN_ROUTE)
      .subscribe(
        (data) => {
          this.requestPreloader = false
          this.ongoingRequests = [];
          if (data.total > 0) {
            const pickupAllRequestArray = data.entities;
            pickupAllRequestArray.forEach((p) => {
              const pickRequest = new PickupRequest();
              pickRequest.mapToPickupRequest(p);

              this.ongoingRequests.push(pickRequest);
            });
            return this.ongoingRequests;
          }
        },
        (error) => {
          this.requestPreloader = false;
          this.errorHandler(error);
        }
      );
  }

  // Error handling
  public errorHandler(error) {
    GeneralUtil.errorHandler(error);
  }
}
