import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { GeneralEnum } from '../constant/general-enum.enum';

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(public httpClient: HttpClient) { }

  public getDashboardStats() {
    const url = `${GeneralEnum.baseUrl}/auth/web/dashboard/stats`;
    return this.httpClient.get<any>(url).pipe(catchError(this.errorHandler));
  }

  getDeliveryByStateStats() {
    const url = GeneralEnum.baseUrl + '/batch/sample/state/total/delivered';
    return this.httpClient.get<any>(url).pipe(catchError(this.errorHandler));
  }

  // Error Handler
  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
