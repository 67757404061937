import { ReportService } from './../../service/report.service';
import { Component,OnInit } from '@angular/core';
import { Reports } from 'src/app/entity/report';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { PaginationDataDto } from 'src/app/dto/pagination-data-dto';
import { GeneralEnum } from 'src/app/constant/general-enum.enum';
import { Facility } from 'src/app/entity/facility';
import { StateService } from 'src/app/service/state.service';
import { GeneralUtil } from 'src/app/util/general-util';

declare function successToast(msg: string): any;
declare function failureToast(msg: string): any;
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  public reportList: Reports[] = []
  public reportListForExport: Reports[] = []
  public facilityCode: string = '';
  public facilityArray: Facility[] = [];
  public searchRequests: Reports[] = [];
  public exporting = false;
  public emailLoader = false;
  public batchHandoff= '../../../assets/media/images/delivery.png'


  public batchId = '';
  public showPreloader = false;
  public facilitySearch = false;
  public batchSearch = false;
  public size: number = 10;
  public sizeControl: number;

  public limitChanged = false;
  // pagination variable
  public currentPage: number = 1;
  public limit: number;
  paginationData = new PaginationDataDto();
  // pagination variable
  public currentPageSearch: number = 1;
  public limitSearch: number;
  paginationSearch = new PaginationDataDto();

  public paginationMax = [];

  constructor(public reportService: ReportService,private stateService: StateService) { }

  ngOnInit(): void {
    this.initializePaginationData();
    this.initializePaginationSearch();
    this.getReport();
    this.getReportForExport();
    this.getAllFacility();
    for (let i = 1; i < 51; i++) {
      if (i % 5 == 0) {
        this.paginationMax.push(i);

      }
    }
  }


  /*********PAGINATION FUNCTIONS***************/
  initializePaginationData() {
    this.paginationData.currentPage = 1;
    this.paginationData.defaultLimit = GeneralEnum.paginationLimit;
    this.paginationData.totalSize = 100;
  }
  initializePaginationSearch() {
    this.paginationSearch.currentPage = 1;
    this.paginationSearch.defaultLimit = GeneralEnum.paginationLimit;
    this.paginationSearch.totalSize = 100;
  }

  getReport() {
    this.batchSearch = false;
    this.facilitySearch = false;

    this.showPreloader = true;
    if (!this.limitChanged) {
      this.limit = this.paginationData.defaultLimit;

    } else {
      this.limit = this.sizeControl;
    }
    this.currentPage = this.paginationData.currentPage;

    this.reportService.getReports(this.currentPage - 1,this.limit)
      .subscribe(
        (data) => {
        this.reportList = [];
          if (data.total > 0) {
            this.paginationData.totalSize = data.total;
            const reportArray = data.entities;
            reportArray.forEach((r) => {
              const report = new Reports();
              report.mapToReport(r);

              this.reportList.push(report);
            });
            this.showPreloader = false;



            return this.reportList;
          }
        },
        (error) => {
          this.errorHandler(error);
          this.showPreloader = false;
        }
      );
  }

  removeComma(text: string) {
    return GeneralUtil.removeComma(text);
  }



  getReportForExport() {
    this.batchSearch = false;
    this.facilitySearch = false;

    this.showPreloader = true;
    if (!this.limitChanged) {
      this.limit = this.paginationData.defaultLimit;

    } else {
      this.limit = this.sizeControl;
    }
    this.currentPage = this.paginationData.currentPage;
    this.reportService.getReports(this.currentPage - 1, 1000)
      .subscribe(
        (data) => {
          this.reportListForExport = [];
          if (data.total > 0) {
            this.paginationData.totalSize = data.total;
            const reportArray = data.entities;
            reportArray.forEach((r) => {
              const report = new Reports();
              report.mapToReport(r);

              this.reportListForExport.push(report);
            });
            this.showPreloader = false;
          return this.reportListForExport.join('');
          }
        },
        (error) => {
          this.errorHandler(error);
          this.showPreloader = false;
        }
      );
  }



  getAllFacility() {
    this.stateService.getAllFacilityList().subscribe((data) => {
      this.facilityArray = [];
      if (data.length > 0) {
        const pickupAllRequestArray = data;
        pickupAllRequestArray.forEach((p) => {
          const pickRequest = new Facility();
          pickRequest.mapToFacility(p);
          this.facilityArray.push(pickRequest);
        });
        this.facilityArray = this.facilityArray.filter(a => !a.name.includes('Airport'))
        this.showPreloader = false;
        return this.facilityArray;
      } else {
        this.showPreloader = false;
        return this.facilityArray;
      }
    },(error) => {
      this.errorHandler(error)
    })
  }
  makeSearchRequest() {
    if (this.facilitySearch) {
      this.getRequestByFacilityCode();
    } else if (this.batchSearch) {
      this.getRequestByBatchId();
    } else {
      return;
    }
  }

  requestByFacility() {
    this.facilitySearch = true;
    if (this.facilityCode == '') {
      this.getReport();
    } else {
      this.initializePaginationSearch();
      this.getRequestByFacilityCode();
    }
  }

  getRequestByFacilityCode() {
    this.showPreloader = true;
    this.limitSearch = this.paginationSearch.defaultLimit;
    this.currentPageSearch = this.paginationSearch.currentPage;

    this.facilityCode = this.facilityCode.replace(/\s/g,'');

    this.facilitySearch = true;
    this.batchSearch = false;
    this.reportService.getReports(this.currentPageSearch - 1,this.limitSearch,this.facilityCode,'facilityCode').subscribe(
      (data) => {
        this.searchRequests = [];
        if (data.total > 0) {
          this.paginationSearch.totalSize = data.total;

          const pickupAllRequestArray = data.entities;
          pickupAllRequestArray.forEach((p) => {
            const pickRequest = new Reports();
            pickRequest.mapToReport(p);

            this.searchRequests.push(pickRequest);
          });
          this.showPreloader = false;
          return this.searchRequests;
        }
        this.showPreloader = false;
      },
      (error) => {
        this.showPreloader = false;
        this.errorHandler(error);
      }
    );
  }

  sendReportToEmail(){
    this.emailLoader = true;
    this.reportService.getMasterReport()
      .subscribe(data => {
        this.emailLoader = false;
        successToast('Report Sent! Check your email.');

      },(error) => {
        this.emailLoader = false;
        this.errorHandler(error);
      })
  }

  searchByBatchId() {
    if (this.batchId == '') {
      this.batchSearch = false;
      this.getReport();
      return;
    }
    this.batchSearch = true;
    this.initializePaginationSearch();
    this.getRequestByBatchId();
  }

  clearFilter(){
    this.batchId = '';
    this.getReport();
  }

  getRequestByBatchId() {
    this.showPreloader = true;
    this.limitSearch = this.paginationSearch.defaultLimit;
    this.currentPageSearch = this.paginationSearch.currentPage;

    this.batchSearch = true;
    this.facilitySearch = false;

    // remove space from the string
    this.batchId = this.batchId.replace(/\s/g,'');
    this.reportService.getReports(this.currentPageSearch - 1,this.limitSearch,this.batchId,'batchId').subscribe(
      (data) => {
        this.searchRequests = [];
        if (data.total > 0) {
          this.paginationSearch.totalSize = data.total;
          const pickupAllRequestArray = data.entities;
          pickupAllRequestArray.forEach((p) => {
            const pickRequest = new Reports();
            pickRequest.mapToReport(p);
            this.searchRequests.push(pickRequest);
          });
          this.showPreloader = false;
          return this.searchRequests;
        }
        else {
          this.showPreloader = false;
          this.errorHandler(data.message);
        }
        this.showPreloader = false;
      },
      (error) => {
        this.showPreloader = false;
        this.errorHandler(error);
      }
    );
  }
  convertDate(input: string) {
    if (input == null) {
      return;
    }
    if (input == 'N/A' || input == '') {
      return;
    }
    let date = GeneralUtil.convertIonDateTimeToDateTimeString(input);
    return date.slice(0,date.indexOf(' '))
  }

  convertTime(input: string) {
    if (input == null) {
      return;
    }
    if (input == 'N/A' || input == '') {
      return;
    }
    let date = GeneralUtil.convertIonDateTimeToDateTimeString(input);
    return date.slice(date.indexOf(' ') + 1);
  }

  changePaginationLimit() {
    if (!this.size) {
      failureToast('Please Enter a number')
      return;
    }
    if (isNaN(this.size)) {
      failureToast('Please Enter a number')
      return;
    } else {

      if (this.size < 1) {
        failureToast('Enter a valid positive number')
        return;
      }
      else {
        this.sizeControl = this.size;
      }
    }

    this.limitChanged = true;
    this.showPreloader = true;
    this.initializePaginationData();
    this.limit = this.sizeControl;
    this.currentPage = this.paginationData.currentPage;
    this.getReport();
  }

  convertSecondsToTime(seconds) {
    if (isNaN(seconds)) {
      return 'N/A';
    }
    if (seconds == null) {
      return 'N/A';
    }
    seconds = Number(seconds);
    const d = Math.floor(seconds / (3600*24));
    const h = Math.floor(seconds % (3600*24) / 3600);
    const m = Math.floor(seconds % 3600 / 60);
    const s = Math.floor(seconds % 60);

    const dDisplay = d > 0 ? d + (d == 1 ? " day " : " days ") : "";
    const hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
    const mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
    const sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
    }

  exportToCsv() {
    this.exporting = true;
    setTimeout(() => {
      GeneralUtil.export2csv('Tracker-report.csv');
      // GeneralUtil.exportTableToCSV('Delivery-Status.csv');
    },200);
    setTimeout(() => {
      this.exporting = false;
    },300);
  }

  // Error Handler
  errorHandler(error: HttpErrorResponse) {
    return GeneralUtil.errorHandler(error);
  }

}
