import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GeneralUtil } from '../../util/general-util';
import { GeneralEnum } from 'src/app/constant/general-enum.enum';
import { LoginDto } from 'src/app/dto/login-dto';
import { RegisterUserDto } from 'src/app/dto/register-dto';
import { UserStatusDto } from 'src/app/dto/user-status-dto';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public static userDetailsEmail = 'TRACKER-WEB-USER-DETAILS-EMAIL';
  public static userDetailsUsername = 'TRACKER-WEB-USER-DETAILS-USERNAME';
  public static userDetailsFullName = 'TRACKER-WEB-USER-DETAILS-FULL-NAME';
  public static userDetailsRole = 'TRACKER-WEB-USER-DETAILS-ROLE';
  public static userDetailsToken = 'TRACKER-WEB-USER-DETAILS-TOKEN';
  public static userDetailsStateId = 'TRACKER-WEB-USER-DETAILS-STATEID';

  constructor(private httpClient: HttpClient) { }

  // Login user
  loginUser(loginDto: LoginDto) {
    const url = GeneralEnum.baseUrl + '/login';
    return this.httpClient
      .post<any>(url,loginDto)
      .pipe(catchError(this.errorHandler));
  }

  // Register user
  registerUser(registerDto: RegisterUserDto) {
    const url = GeneralEnum.baseUrl + '/register/user';

    return this.httpClient
      .post<any>(url,registerDto)
      .pipe(catchError(this.errorHandler));
  }

  toggleUserStatus(userData: UserStatusDto) {
    const url = GeneralEnum.baseUrl + '/admin/update/status';

    return this.httpClient
      .patch<any>(url,userData)
      .pipe(catchError(this.errorHandler));
  }

  // Confirm if user is logged in
  isLoggedIn() {
    return !!localStorage.getItem(AuthService.userDetailsToken);
  }

  // Get Token value
  getToken() {
    return localStorage.getItem(AuthService.userDetailsToken);
  }

  // Get Authorization Token
  getAuthToken() {
    if (this.getToken() == null) {
      return 'Bearer ';
    } else {
      return 'Bearer ' + this.getToken();
    }
  }

  // after login, set user details
  public setUserDetails(data: any) {
    localStorage.setItem(AuthService.userDetailsEmail,data.email);
    localStorage.setItem(AuthService.userDetailsUsername,data.username);
    localStorage.setItem(AuthService.userDetailsFullName,data.fullName);
    localStorage.setItem(AuthService.userDetailsRole,data.role.name);
    localStorage.setItem(AuthService.userDetailsToken,data.token);
    localStorage.setItem(AuthService.userDetailsStateId,data.state.id);
  }

  // reset user details
  public resetUserDetails() {
    localStorage.removeItem(AuthService.userDetailsEmail);
    localStorage.removeItem(AuthService.userDetailsUsername);
    localStorage.removeItem(AuthService.userDetailsFullName);
    localStorage.removeItem(AuthService.userDetailsRole);
    localStorage.removeItem(AuthService.userDetailsToken);
    localStorage.removeItem(AuthService.userDetailsStateId);
  }

  public getUserDetailItem(userDetailItem: string): string {
    const userDetail = localStorage.getItem(userDetailItem);
    if (!GeneralUtil.isValidString(userDetail)) {
      return null;
    }
    return userDetail;
  }

  public setOneUserDetail(userDetailItem: string,value: string) {
    const userDetail = localStorage.getItem(userDetailItem);

    if (GeneralUtil.isValidString(userDetail)) {
      localStorage.setItem(userDetailItem,value);
    }
  }

  // Error Handler
  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
