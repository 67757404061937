import { GeneralUtil } from '../util/general-util';
import { State } from './state';

export class Facility {
  public id: number;
  public code: string;
  public name: string;
  public phoneNumber: string;
  public longitude: number;
  public latitude: number;
  public state = new State();

  public mapToFacility(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.id = data.id;
      this.code = data.code;
      this.name = data.name;
      this.phoneNumber = data.phoneNumber;
      this.longitude = data.longitude;
      this.latitude = data.latitude;
      this.state.mapToState(data.state)
    }
  }
}
