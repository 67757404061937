import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PickupRequest } from 'src/app/entity/pickup-request';
import { DeliveriesService } from 'src/app/service/deliveries.service';
import { GeneralUtil } from 'src/app/util/general-util';

@Component({
  selector: 'app-batch-details',
  templateUrl: './batch-details.component.html',
  styleUrls: ['./batch-details.component.scss']
})
export class BatchDetailsComponent implements OnInit {
  public requestId: number;
  @Input() pickupRequest: PickupRequest;
  public showPreloader = false;
  public showLoader = false;
  public previousBatchTrip = new PickupRequest();
  public queryStatus = '';

  public batch: PickupRequest;

  constructor(public route: ActivatedRoute,
    public requestService: DeliveriesService) { }

  ngOnInit(): void {
    this.requestId = Number(this.route.snapshot.paramMap.get('requestId'));
    this.getBatch(this.requestId)
      this.route.queryParams.subscribe((data) => {
      if (data.status !== '') {
        this.queryStatus = data.status;
      }
     })
  }

  getBatch(requestId) {
    this.showPreloader = true;
    this.batch = new PickupRequest();
    this.requestService.getOneBatch(requestId)
      .subscribe(data => {
        this.batch.mapToPickupRequest(data);
        this.showPreloader = false;
         this.getPreviousBatchTrip(this.batch.batchId)
        return this.batch;
      },
        error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });
  }


  getPreviousBatchTrip(batchId) {
    this.showLoader = true
    this.requestService.getPreviousTrip(batchId)
      .subscribe(data => {
        this.showLoader = false;
        this.previousBatchTrip.mapToPickupRequest(data)
        return this.previousBatchTrip;
      },
        error1 => {
          this.showLoader = false
          this.errorHandler(error1);
        });
  }
  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }

}
