import { GeneralUtil } from '../util/general-util';

export class DeliveryByState {
  public bauchi: number;
  public edo: number;
  public kaduna: number;
  public katsina: number;
  public kano: number;
  public nasarawa: number;
  public plateau: number;
  public taraba: number;
  public anambra: number;
  public akwaIbom: number;
  public benue: number;
  public crossRiver: number;
  public delta: number;
  public imo: number;
  public rivers: number;
  public abuja: number;
  public enugu: number;
  public lagos: number;

  public mapToDelivery(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.abuja = data.abuja;
      this.akwaIbom = data.akwaIbom;
      this.anambra = data.anambra;
      this.bauchi = data.bauchi;
      this.benue = data.benue;
      this.crossRiver = data.crossRiver;
      this.delta = data.delta;
      this.edo = data.edo;
      this.enugu = data.enugu;
      this.imo = data.imo;
      this.kaduna = data.kaduna;
      this.kano = data.kano;
      this.katsina = data.katsina;
      this.lagos = data.lagos;
      this.nasarawa = data.nasarawa;
      this.plateau = data.plateau;
      this.rivers = data.rivers;
      this.taraba = data.taraba;
    }
  }
}
