import { UpdateBatchDto } from './../dto/update-batch-dto';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { GeneralEnum } from "../constant/general-enum.enum";

@Injectable({
  providedIn: "root",
})
export class DeliveriesService {
  constructor(public httpClient: HttpClient) { }

  public getAllPickUpRequests(page, size: number, status: string, batchId?: string
  ) {
    let url;

    if (batchId) {
      url =
        GeneralEnum.baseUrl + "/auth/web/batch/status?batchId=" + batchId + "&page=" + page + "&size=" + size + "&statusConstant=" + status;
    } else {
      url =
        GeneralEnum.baseUrl + "/auth/web/batch/status?" + "page=" + page + "&size=" + size + "&statusConstant=" + status;
    }

    return this.httpClient.get<any>(url).pipe(catchError(this.errorHandler));
  }

  public getAllHandOffs(page, size) {
    let url =

      GeneralEnum.baseUrl + "/auth/request/handOff/all?" + "&page=" + page + "&size=" + size;
    return this.httpClient.get<any>(url).pipe(catchError(this.errorHandler));
  }

  public getHandOffsByBatchId(page, size, batchId) {
    let url = GeneralEnum.baseUrl + "/auth/request/handOff/all?batchId=" + batchId + "&page=" + page + "&size=" + size;
    return this.httpClient.get<any>(url).pipe(catchError(this.errorHandler));
  }


  public updateBatchDetails(updateBatch: UpdateBatchDto) {
    const url =
      GeneralEnum.baseUrl + '/request/update';
    return this.httpClient.patch<any>(url, updateBatch)
      .pipe(catchError(this.errorHandler));
  }


  public deleteBatch(batchId: string) {
    const url = GeneralEnum.baseUrl + '/request/delete/' + batchId;
    return this.httpClient.delete<any>(url).pipe(catchError(this.errorHandler));
  }


  public getAllFacility(page: number, size: number) {
    let url = GeneralEnum.baseUrl + "/auth/facilities?" + "page=" + page + "&size=" + size;
    return this.httpClient.get<any>(url).pipe(catchError(this.errorHandler));
  }
  public getSuperAdmin(page: number, limit: number, searchTerm?: string) {
    let url;
    if (searchTerm) {
      url = GeneralEnum.baseUrl + "/superAdmins?keyword=" + searchTerm + "&page=" + page + "&limit=" + limit;
    } else {
      url = GeneralEnum.baseUrl + "/superAdmins?" + "page=" + page + "&limit=" + limit;

    }
    return this.httpClient.get<any>(url).pipe(catchError(this.errorHandler));
  }

  public getCourierAdmin(page: number, limit: number, searchTerm?: string) {
    let url;
    if (searchTerm) {
      url = GeneralEnum.baseUrl + "/courierAdmins?keyword=" + searchTerm + "&page=" + page + "&limit=" + limit;
    }
    else {
      url = GeneralEnum.baseUrl + "/courierAdmins?" + "page=" + page + "&limit=" + limit;
    }
    return this.httpClient.get<any>(url).pipe(catchError(this.errorHandler));
  }

  public getCentralAdmin(page: number, limit: number, searchTerm?: string) {
    let url;
    if (searchTerm) {
      url = GeneralEnum.baseUrl + "/centralAdmins?keyword=" + searchTerm + "&limit=" + limit + "&page=" + page;
    }
    else {
      url = GeneralEnum.baseUrl + "/centralAdmins?" + "page=" + page + "&limit=" + limit;
    }
    return this.httpClient.get<any>(url).pipe(catchError(this.errorHandler));
  }



  public getCourierAgent(page: number, limit: number, searchTerm?: string) {
    let url;
    if (searchTerm) {
      url = GeneralEnum.baseUrl + "/auth/courierAgent?keyword=" + searchTerm + "&page=" + page + "&limit=" + limit;
    }
    else {
      url = GeneralEnum.baseUrl + "/auth/courierAgent?" + "page=" + page + "&limit=" + limit;

    }
    return this.httpClient.get<any>(url).pipe(catchError(this.errorHandler));
  }

  // get Previous Trip Details For PickUpRequest
  getPreviousTrip(batchId) {
    const url = GeneralEnum.baseUrl + '/auth/request/' + batchId + '/previous/trip';
      return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }


  // get batch details
   public getOneBatch(pickUpRequestId : number) {
    const url = GeneralEnum.baseUrl + '/auth/request/' + pickUpRequestId;
    return this.httpClient.get<any>(url).pipe(catchError(this.errorHandler));
  }

  // Error Handler
  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
