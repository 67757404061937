import { ResetPasswordDto } from './../../dto/forgot-password-dto';
import { GeneralUtil } from './../../util/general-util';
import { Component,OnInit } from '@angular/core';
import { State } from 'src/app/entity/state';
import { Router } from '@angular/router';
import { LoginDto } from 'src/app/dto/login-dto';
import { AuthService } from 'src/app/service/auth/auth.service';
import { StateService } from 'src/app/service/state.service';
import { RegisterUserDto } from 'src/app/dto/register-dto';
import { UserType } from 'src/app/constant/user-type.enum.enum';
import { NgForm } from '@angular/forms';
import { UserService } from 'src/app/service/user.service';

declare function successToast(msg): any;
declare function failureToast(msg): any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginDto = new LoginDto();
  public showPreloader = false;
  public signedUp = false;
  public registerDto = new RegisterUserDto();
  public states: State[] = [];
  public loginText = 'Login';
  public registerText = 'Register';
  public userType = UserType;
  public updatePassword = false;
  public resetPasswordDto = new ResetPasswordDto();
  public passwordHide = true;
  

  constructor(
    public authService: AuthService,
    public router: Router,
    public stateService: StateService,
    public userService: UserService
  ) { }

  ngOnInit(): void {
    this.userLoginCheck();
    this.getStates();
  }

  togglePassword() {
    this.passwordHide = !this.passwordHide;
  }

  loginAction(formData: NgForm) {
    this.showPreloader = true;
    this.loginText = 'Please Wait...'
     this.authService.loginUser(this.loginDto).subscribe(
      (data) => {
        this.showPreloader = false;
        formData.reset();
        this.loginText = 'Login';
        setTimeout(() => {
          this.authService.resetUserDetails();
          this.authService.setUserDetails(data);
          if (this.authService.getUserDetailItem('TRACKER-WEB-USER-DETAILS-ROLE') == 'USER') {
            successToast('Logged in successfully');
          }
          this.router.navigate(['/dashboard']);
        },

        500);



      },
      (error) => {
        this.showPreloader = false;
        formData.reset();
        this.loginText = 'Login';
        this.errorHandler(error);

      }
    );
  }

  // check if user is already logged in
  public userLoginCheck() {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/dashboard']);
    }
  }
  sendResetPasswordMail(){
    this.resetPasswordDto.callBackUrl = GeneralUtil.getLocationUrl();
    console.log(this.resetPasswordDto.callBackUrl);

    this.showPreloader = true;
    this.userService.sendResetPasswordMail(this.resetPasswordDto)
      .subscribe(data => {
        this.showPreloader = false;
        successToast('Email reset link sent to your mail');
        setTimeout(() => {
          this.updatePassword = false;
        },500)
      },(error) => {
        this.showPreloader = false;
        this.errorHandler(error);
      })
  }

  getStates() {
    this.states = [];

    this.stateService.getStates().subscribe((data) => {
      if (data) {
        const stateArray = data;
        stateArray.forEach((s) => {
          const state = new State();
          state.mapToState(s);
          this.states.push(state);
        });
        return this.states;
      }
    });
  }

  // Error handling
  public errorHandler(error) {
    GeneralUtil.errorHandler(error);
  }
}
