import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { UpdatePasswordDto } from 'src/app/dto/forgot-password-dto';
import { AuthService } from 'src/app/service/auth/auth.service';
import { UserService } from 'src/app/service/user.service';
import { GeneralUtil } from 'src/app/util/general-util';
declare function successToast(msg): any;
declare function failureToast(msg): any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public passwordTypeOld = true;
  public passwordTypeNew = true;
  public passwordTypeConfirm = true;
  public forgotPasswordDto = new UpdatePasswordDto();
  public confirmPassword = '';
  public showPreloader = false;
  public resetPasswordLoader = false;
  @ViewChild('closeResetPassword') closeResetPassword: ElementRef;

  constructor(public authService: AuthService,
              public router: Router,
              public userService: UserService,) { }

  ngOnInit(): void {
  }

  removeUnderscore(text: string) {
    return GeneralUtil.removeUnderscore(text);
  }

  togglePasswordOld() {
    this.passwordTypeOld = !this.passwordTypeOld;
  }

  togglePasswordNew() {
    this.passwordTypeNew = !this.passwordTypeNew;
  }

  togglePasswordConfirm() {
    this.passwordTypeConfirm = !this.passwordTypeConfirm;
  }

  changePassword(resetPassword: NgForm) {
    if (this.confirmPassword !== this.forgotPasswordDto.newPassword) {
      failureToast('Password does not match')
      return
    }
    this.resetPasswordLoader = true;
    this.userService.updatePassword(this.forgotPasswordDto)
      .subscribe(data => {
        this.resetPasswordLoader = false;
        successToast('Password updated successfully');
        this.closeResetPassword.nativeElement.click();
        setTimeout(() => {
          this.authService.resetUserDetails();
          this.router.navigate(['/login']);
          resetPassword.reset();
        }, 500)
      },
        error1 => {
          this.resetPasswordLoader = false;
          this.errorHandler(error1)
        }
      )
  }
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }

}
