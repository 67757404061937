import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ForgotPasswordDto } from 'src/app/dto/forgot-password-dto';
import { UserService } from 'src/app/service/user.service';
import { GeneralUtil } from 'src/app/util/general-util';

declare function successToast(msg): any;
declare function failureToast(msg): any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public passwordLength = false;
  public passwordTextType = true;
  public forgotPasswordDto = new ForgotPasswordDto();
  public confirmPasswordTextType = true;
  public error = '';
  public validateToken = false;
  public showPreloader = false;
  public token: string;

  constructor(public route: ActivatedRoute,
            public userService: UserService,
            public router: Router) { }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token');
    this.validatePasswordResetToken();
  }

  validatePasswordResetToken() {
 this.userService.validatePasswordResetToken(this.token)
      .subscribe(data => {
        this.validateToken = true;
    },  error1 => this.errorHandler(error1))
  }

  changePassword(){
    if(this.forgotPasswordDto.confirmPassword !== this.forgotPasswordDto.newPassword){
      failureToast('Password does not match')
      return
    }
    this.forgotPasswordDto.token = this.token;
    this.showPreloader = true;
    this.userService.changePassword(this.forgotPasswordDto)
      .subscribe(data => {
        this.showPreloader = false;
        successToast('Password changed successfully. Proceed to login.');
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 500)
    },
    error1 => {
      this.showPreloader = false;
      this.errorHandler(error1)
    }
    )
  }

  toggleConfirmPassword() {
    this.confirmPasswordTextType = !this.confirmPasswordTextType;
  }

  checkPasswordLength() {
    if (this.forgotPasswordDto.newPassword.length >= 8 && this.forgotPasswordDto.confirmPassword.length >= 8) {
      this.passwordLength = true;
    } else {
      this.passwordLength = false;
    }
  }

  togglePassword() {
    this.passwordTextType = !this.passwordTextType;
  }

   // Error handling
   errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }

}
