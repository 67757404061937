import { GeneralUtil } from '../util/general-util';

export class Role {
 public id: number;
 public name: string;

 public mapToRole(data: any) {
  if (GeneralUtil.isValidJSON(data)) {
   this.id = data.id;
   this.name = data.name;
  }
 }
}
