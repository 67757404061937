import { UpdateUser } from './../entity/update-user-dto';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GeneralEnum } from '../constant/general-enum.enum';
import { ForgotPasswordDto, ResetPasswordDto, UpdatePasswordDto } from '../dto/forgot-password-dto';
import { UserDto } from '../entity/user-dto';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) { }
  registerUser(newUser: UserDto) {
    const url = GeneralEnum.baseUrl + '/register/user';
    return this.httpClient.post<any>(url,newUser).pipe(catchError(this.errorHandler));
  }

  public updatePassword(passwordDto: UpdatePasswordDto) {
    const url = GeneralEnum.baseUrl + '/auth/courierAgent/update/password';
    return this.httpClient.post<any>(url,passwordDto)
      .pipe(catchError(this.errorHandler));
  }

  public validatePasswordResetToken(token: string) {
    const url = GeneralEnum.baseUrl + '/user/validate/' + token;
    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }
  public changePassword(passwordDto: ForgotPasswordDto) {
    const url = GeneralEnum.baseUrl + '/user/change/password';
    return this.httpClient.post<any>(url,passwordDto)
      .pipe(catchError(this.errorHandler));
  }

  public sendResetPasswordMail(resetPasswordDto: ResetPasswordDto) {
    const url = GeneralEnum.baseUrl + '/user/reset/password';
    return this.httpClient.post<any>(url,resetPasswordDto)
      .pipe(catchError(this.errorHandler));
  }

  public updateUser(updateUserDto: UpdateUser){
    const url = GeneralEnum.baseUrl + '/admin/update';

    return this.httpClient.put<any>(url, updateUserDto)
      .pipe(catchError(this.errorHandler));

  }

  // Error Handler
  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
