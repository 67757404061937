import { GeneralUtil } from '../util/general-util';

export class AdminDashboardStatsDto{
    public pending: number;
    public ongoing : number;
    public delivered: number;
    public handoff: number;

    constructor() { }

  public mapToAdminStats(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
        this.pending = data.pending.toLocaleString();
        this.ongoing = data.ongoing.toLocaleString();
        this.delivered = data.delivered.toLocaleString();
        this.handoff = data.handoff.toLocaleString();
    }
  }

}
