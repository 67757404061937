import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import {tap} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor{

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const tokenizedReq = req.clone({
      setHeaders: {
        Authorization: this.authService.getAuthToken()
      }
    });

    return next.handle(tokenizedReq).pipe( tap(() => {},
    (err: any) => {
    if (err instanceof HttpErrorResponse) {
      if (err.status == 401 && err.error.message == "Invalid Login details") {
        this.authService.resetUserDetails();
        this.router.navigate(['/login']);
        
      }
      
    }
  }));
}
constructor(private authService: AuthService,
  public router: Router,) { }
}

