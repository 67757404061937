import { AuditTrailComponent } from './components/audit-trail/audit-trail.component';
import { HomeComponent } from './components/home/home.component';
import { NgModule } from '@angular/core';
import { Routes,RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './service/auth/auth.guard';
import { ReportsComponent } from './components/reports/reports.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { BatchDetailsComponent } from './components/batch-details/batch-details.component';


const routes: Routes = [
  { path: '',redirectTo: '/home',pathMatch: 'full' },
  { path: 'login',pathMatch: 'full',component: LoginComponent },
  { path: 'home',pathMatch: 'full',component: HomeComponent },
  { path: 'forgot-password/:token',pathMatch: 'full',component: ForgotPasswordComponent },
  { path: 'dashboard',pathMatch: 'full',component: DashboardComponent,canActivate: [AuthGuard] },
  { path: 'deliveries',loadChildren: () => import('./modules/deliveries/deliveries.module').then(m => m.DeliveriesModule),canActivate: [AuthGuard] },
  { path: 'facility-management',loadChildren: () => import('./modules/facility-management/facility-management.module').then(m => m.FacilityManagementModule),canActivate: [AuthGuard] },
  { path: 'profile',pathMatch: 'full',component: ProfileComponent,canActivate: [AuthGuard] },
  { path: 'user-management',loadChildren: () => import('./modules/user-management/user-management.module').then(m => m.UserManagementModule),canActivate: [AuthGuard] },
  { path: 'reports',pathMatch: 'full',component: ReportsComponent,canActivate: [AuthGuard] },
  { path: 'audit-trail',pathMatch: 'full',component: AuditTrailComponent,canActivate: [AuthGuard] },
  { path: 'batch-details/:requestId',pathMatch: 'full',component: BatchDetailsComponent,canActivate: [AuthGuard] },
  { path: 'unauthorized',component: UnauthorizedComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
