import { GeneralUtil } from '../util/general-util';

export class Reports {
    public assignedBy: string;
    public assignedByPhoneNumber: string;
    public batchDeliveryToReceiptDuration: string;
    public batchId: string;
    public batchPickUpToDeliveryDuration: string;
    public consignmentNumber: string;
    public courierAgentPhoneNumber: string;
    public courierAgent: string;
    public dateAssigned: string;
    public dateCreated: string;
    public deliveryAgent: string;
    public deliveryAgentPhoneNumber: string;
    public deliveryAssignedBy: string;
    public deliveryAssignedByPhoneNumber: string;
    public finalDestination: string;
    public deliveryNote: string;
    public deliveryOrigin: string;
    public destination: string;
    public facilityOfficer: string;
    public flightArrivalDateTime: string;
    public flightDepartureDateTime: string;
    public handOffLocation: string;
    public handOffRequestToPickUpDuration: boolean;
    public handOffTime: string;
    public handover: boolean;
    public origin: string;
    public pickUpRequestPickUpDuration: number;
    public pickUpTemperature: number;
    public receivingOfficerName: number;
    public receivingOfficerPhone: number;
    public temperatureAtDelivery: string;
    public temperatureDifference: string;
    public timeAccepted: string;
    public timeArriveNrl: string;
    public timeDelivered: string;
    public timeDeliveryConfirmed: string;
    public timeEnRoute: string;
    public timeEnRouteNrl: string;
    public timeReAccepted: string;
    public timeReInitiated: string;
    public timeReassigned: string;
    public batchInitiatedToDeliveryDuration: string;
    public handOffNote: string;


    public mapToReport(data: any) {
        if (GeneralUtil.isValidJSON(data)) {
            this.assignedBy = data.assignedBy;
            this.assignedByPhoneNumber = data.assignedByPhoneNumber;
            this.batchDeliveryToReceiptDuration = data.batchDeliveryToReceiptDuration;
            this.batchId = data.batchId;
            this.batchPickUpToDeliveryDuration = data.batchPickUpToDeliveryDuration;
            this.consignmentNumber = data.consignmentNumber;
            this.courierAgentPhoneNumber = data.courierAgentPhoneNumber;
            this.courierAgent = data.courierAgent;
            this.dateAssigned = data.dateAssigned;
            this.dateCreated = data.dateCreated;
            this.deliveryAgent = data.deliveryAgent;
            this.deliveryAgentPhoneNumber = data.deliveryAgentPhoneNumber;
            this.deliveryAssignedBy = data.deliveryAssignedBy;
            this.deliveryAssignedByPhoneNumber = data.deliveryAssignedByPhoneNumber;
            this.finalDestination = data.finalDestination;
            this.deliveryNote = data.deliveryNote;
            this.deliveryOrigin = data.deliveryOrigin;
            this.destination = data.destination;
            this.facilityOfficer = data.facilityOfficer;
            this.flightArrivalDateTime = data.flightArrivalDateTime;
            this.flightDepartureDateTime = data.flightDepartureDateTime;
            this.handOffLocation = data.handOffLocation;
            this.handOffRequestToPickUpDuration = data.handOffRequestToPickUpDuration;
            this.handOffTime = data.handOffTime;
            this.handover = data.handover;
            this.origin = data.origin;
            this.pickUpRequestPickUpDuration = data.pickUpRequestPickUpDuration;
            this.pickUpTemperature = data.pickUpTemperature;
            this.receivingOfficerName = data.receivingOfficerName;
            this.receivingOfficerPhone = data.receivingOfficerPhone;
            this.temperatureAtDelivery = data.temperatureAtDelivery;
            this.temperatureDifference = data.temperatureDifference;
            this.timeAccepted = data.timeAccepted;
            this.timeArriveNrl = data.timeArriveNrl;
            this.timeDelivered = data.timeDelivered;
            this.timeDeliveryConfirmed = data.timeDeliveryConfirmed;
            this.timeEnRoute = data.timeEnRoute;
            this.timeEnRouteNrl = data.timeEnRouteNrl;
            this.timeReAccepted = data.timeReAccepted;
            this.timeReInitiated = data.timeReInitiated;
            this.timeReassigned = data.timeReassigned;
            this.batchInitiatedToDeliveryDuration = data.batchInitiatedToDeliveryDuration;
            this.handOffNote = data.handOffNote;
        }
    }
}
