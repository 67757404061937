import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpClient,HttpErrorResponse } from "@angular/common/http";
import { GeneralEnum } from "../constant/general-enum.enum";

@Injectable({
  providedIn: "root",
})
export class StateService {
  constructor(public httpClient: HttpClient) { }

  public getStates() {
    const url = GeneralEnum.baseUrl + "/api/states";

    return this.httpClient.get<any>(url).pipe(catchError(this.errorHandler));
  }

  public getAllFacility(page: number,size: number) {
    let url = GeneralEnum.baseUrl + "/auth/facilities?" + "page=" + page + "&size=" + size;
    return this.httpClient.get<any>(url).pipe(catchError(this.errorHandler));
  }
  public getRequestByFacilityCode(page: number,size: number,facilityCode: string,status?: string) {
    let url;
    if (status) {
      url = GeneralEnum.baseUrl + `/auth/all/request/${facilityCode}?` + "page=" + page + "&size=" + size + "&status=" + status;
    } else {
      url = GeneralEnum.baseUrl + `/auth/all/request/${facilityCode}?` + "page=" + page + "&size=" + size;
    }
    return this.httpClient.get<any>(url).pipe(catchError(this.errorHandler));
  }

  public getRequestByBatchId(page: number,size: number,batchId: string,status?: string) {
    let url;
    if (status) {
      url = GeneralEnum.baseUrl + `/auth/all/requests/${batchId}?` + "page=" + page + "&size=" + size + "&status=" + status;

    } else {
      url = GeneralEnum.baseUrl + `/auth/all/requests/${batchId}?` + "page=" + page + "&size=" + size;

    }

    return this.httpClient.get<any>(url).pipe(catchError(this.errorHandler));
  }

  public getAllFacilityList() {
    let url = GeneralEnum.baseUrl + "/api/facility/codes";
    return this.httpClient.get<any>(url).pipe(catchError(this.errorHandler));
  }

  // Error Handler
  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
