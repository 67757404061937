import { GeneralUtil } from 'src/app/util/general-util';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './../../service/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('close') close: ElementRef;
  @ViewChild('hamburger') hamburger: ElementRef;
  constructor(public authService: AuthService, public router: Router) { }

  ngOnInit(): void {
  }

  logout() {
    this.authService.resetUserDetails();
    this.router.navigate(['/login']);
  }

  removeUnderscore(text: string) {
    return GeneralUtil.removeUnderscore(text);
  }

}
