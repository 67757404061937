
 import { State } from 'src/app/entity/state';
 import { GeneralUtil } from '../util/general-util';

 export class OriginFacility{
  public code: string;
  public name: string;
  public phoneNumber: string;
  public longitude: number;
  public latitude: number;
  public state = new State();

  public mapToOriginFacility(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.code = data.code;
      this.name = data.name;
      this.phoneNumber = data.phoneNumber;
      this.longitude = data.longitude;
      this.latitude = data.latitude;
      this.state.mapToState(data.state);
    }
  }

}