import { GeneralUtil } from '../util/general-util';

export class State {
  public id: number;
  public name: string;

  public mapToState(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.id = data.id;
      this.name = data.name;
    }
  }
}
