import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";

declare function failureToast(msg): any;

export class GeneralUtil {
  static route: Router;

  static getDate(timestamp) {
    const date = new Date(timestamp * 1000);

    // format
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutes2 = minutes < 10 ? '0' + minutes.toString() : minutes;
    const strTime = hours + ':' + minutes2 + ' ' + ampm;

    const month = this.getMonth(date.getMonth() + 1);
    return date.getDate() + ' ' + month + ' ' + date.getFullYear();
  }

  static getDateTime(timestamp) {
    const date = new Date(timestamp * 1000);

    // format
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutes2 = minutes < 10 ? '0' + minutes.toString() : minutes;
    const strTime = hours + ':' + minutes2 + ' ' + ampm;

    const month = this.getMonth(date.getMonth() + 1);
    return date.getDate() + ' ' + month + ' ' + date.getFullYear() + ' ' + strTime;
  }

  static getMonth(monthValue) {

    let month = null;

    switch (monthValue) {
      case 1:
        month = 'January';
        break;
      case 2:
        month = 'February';
        break;
      case 3:
        month = 'March';
        break;
      case 4:
        month = 'April';
        break;
      case 5:
        month = 'May';
        break;
      case 6:
        month = 'June';
        break;
      case 7:
        month = 'July';
        break;
      case 8:
        month = 'August';
        break;
      case 9:
        month = 'September';
        break;
      case 10:
        month = 'October';
        break;
      case 11:
        month = 'November';
        break;
      case 12:
        month = 'December';
        break;


    }
    return month;
  }

  static getRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  static isValidString(astring: any): boolean {
    if (astring !== undefined && astring !== '' && astring !== null) {
      return true;
    }
    return false;
  }

  static isValidJSON(json: any): boolean {
    if (json == null || json === '' || json === undefined) {
      return false;
    }

    const str = JSON.stringify(json);

    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }

    return true;
  }

  static convertDateStringToAge(date: string) {
    if (!this.isValidString(date)) {
      return null;
    }
    const age = date.split('-');
    const birthYear = Number(age[2]);
    const today = new Date();
    const thisYear = today.getFullYear();
    const year = (thisYear - birthYear);
    if (year === 1) {
      return '1 year';
    } else {
      return `${year} years`;
    }
  }

  /**
   * Convert 2000-08-12T13:06:46.631+01:00 datetimeformat to
   * 12-09-2020 dateformat
   */
  static convertDateTimeToDateformat(dateTime: string) {
    if (!this.isValidString(dateTime)) {
      return null;
    }

    const dateString = dateTime.split('T')[0];
    const dateArray = dateString.split('-');
    return dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
  }

  /**
   * Convert 2000-08-12T13:06:46.631+01:00 datetimeformat to
   * 2020-09-12 dateformat
   */
  static convertDateTimeToDateInputFormat(dateTime: string) {
    if (dateTime != null) {
      const dateString = dateTime.split('T')[0];
      const dateArray = dateString.split('-');
      return dateArray[0] + '-' + dateArray[1] + '-' + dateArray[2];
    }
  }

  /* sets the date string that comes from the server to
  ISO 8601 Datetime Format: YYYY-MM-DDTHH:mm:ssTZ for ionic date-time component */
  static convertDateStringToIsoDateTimeFormat(patientDate: string) {
    if (patientDate != null) {
      const date = patientDate.split('-');
      date.reverse();
      const dateString = new Date(date.toString());
      return dateString.toISOString();
    }
  }

  static removeUnderscore(mystring: string): string {
    if (this.isValidString(mystring)) {
      return mystring.replace(/_/g,' ')
    };
  }


  static removeComma(mystring: string): string {
    if (this.isValidString(mystring)) {
      return mystring.replace(/,/g, '')
    };
  }

  /**
   * Convert 2000-08-12T13:06:46.631+01:00 datetimeformat to
   * dd-MM-yyyy hh:mm a dateformat
   */
  static convertIonDateTimeToDateTimeString(dateTime: string) {
    if (!this.isValidString(dateTime)) {
      return null;
    }
    if (dateTime == "N/A") {
      return null;
    }

    const dateString = dateTime.split('T')[0];
    const timeString = dateTime.split('T')[1];
    const timeArray = timeString.split(':');
    let period: string;
    let hour: string;
    if (parseInt(timeArray[0]) < 12) {
      period = 'AM';
      hour = timeArray[0];
    } else {
      period = 'PM';
      const hourNumber = parseInt(timeArray[0]) - 12;
      if (hourNumber > 9) {
        hour = hourNumber.toString();
      } else {
        hour = '0' + hourNumber.toString();
      }
    }

    const minute = timeArray[1];
    const time = hour + ':' + minute + ' ' + period;

    const dateArray = dateString.split('-');
    const date = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
    return date + ' ' + time;
  }

  // convert estimated age to Date of Birth eg: 5years to 15-02-2016, 5months to 15-08-2020, 5days to 10-02-2021
  static getDateOfBirthByAgeRange(ageRange,ageNumber) {
    if (ageRange != null && ageNumber != null) {

      switch (ageRange) {
        case 'years':
          const year = new Date().getFullYear() - ageNumber;
          const DOB = '01-01-' + year;
          return DOB;

        case 'months':
          let today = new Date();
          let dd = Number(String(today.getDate()).padStart(2,'0'));
          let mm = Number(String(today.getMonth()).padStart(2,'0'));
          let yyyy = today.getFullYear();

          let currentDate = new Date(yyyy,mm,dd);
          currentDate.setMonth(currentDate.getMonth() + -ageNumber)
          return currentDate.toString();

        case 'days':
          today = new Date();
          dd = Number(String(today.getDate()).padStart(2,'0'));
          mm = Number(String(today.getMonth()).padStart(2,'0'));
          yyyy = today.getFullYear();

          currentDate = new Date(yyyy,mm,dd);
          currentDate.setDate(currentDate.getDate() + -ageNumber)


          return currentDate.toString();

        default:
          return;
      }
    }
  }

  /* convert date array to DD-MM-YYYY format */
  static convertDateArrayToDdMmYyyy(date) {
    const dateTime = date;
    const dDMmYy = `${dateTime[2]}-${dateTime[1]}-${dateTime[0]}`;
    return dDMmYy;
  }

  /* convert date object to dd-mm-yyyy */
  static convertDateToDdMmYyyy(date) {

    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day,month,year].join('-');

  }

  /* Get one previous month from dd-mm-yyyy */
  static getPreviousMonth(date) {
    const d = date.toString().split('-');
    if (parseInt(d[1]) === 1) {
      d[1] = '12';
      d[2] = (parseInt(d[2]) - 1).toString();
    }
    d[1] = (parseInt(d[1]) - 1).toString().padStart(2,'0');
    return d.join('-');
  }

  // cornelPrime
  static exportTableToCSV(filename) {
    var csv = [];
    var rows = document.querySelectorAll("table tr");

    for (var i = 0; i < rows.length; i++) {
      var row = [],cols: any = rows[i].querySelectorAll("td, th");
      for (var j = 0; j < cols.length; j++)
        row.push('"' + cols[j].innerText + '"');
      csv.push(row.join(","));
    }
    // Download CSV file
    this.downloadCSV(csv.join("\n"),filename);
  }
  // CornelPrime
  static downloadCSV(csv,filename) {
    var csvFile;
    var downloadLink;
    // CSV file
    csvFile = new Blob([csv],{ type: "text/csv" });
    // Download link
    downloadLink = document.createElement("a");
    // File name
    downloadLink.download = filename;
    // Create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);
    // Hide download link
    downloadLink.style.display = "none";
    // Add the link to DOM
    document.body.appendChild(downloadLink);
    // Click download link
    downloadLink.click();
  }
  /* export a Table to CSV format */
  static export2csv(exportName: string) {
    let data = '';
    const tableData = [];
    const rows: any = document.querySelectorAll('table tr');
    rows.forEach((row) => {
      const rowData = [];
      for (const [index, column] of row.querySelectorAll('th, td').entries()) {
        // To retain the commas in the "Description" column, we can enclose those fields in quotation marks.
        if ((index + 1) % 3 === 0) {
          rowData.push('"' + column.innerText + '"');
        } else {
          rowData.push(column.innerText);
        }
      }
      tableData.push(rowData.join(','));
    });
    data += tableData.join('\n');
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([data], {type: 'text/csv'}));
    a.setAttribute('download', exportName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }


  // reversing yyyy-mm-dd to dd-mm-yyy
  static reverseDateFormat(oldDate) {
    if (!this.isValidString(oldDate)) {
      return null;
    }
    const date = oldDate.split('-');
    return date[2] + '-' + date[1] + '-' + date[0];

  }

  // convert 04-02-2021 12:25 PM to 2021-02-04T12:25
  static convertDateTimeToDateTimeInput(dateTime: string) {
    if (!this.isValidString(dateTime)) {
      return null;
    }

    const dateTimeArray = dateTime.split(' ');
    const dateString = dateTimeArray[0];
    /* Reverse the date order */
    const dateStringArray = dateString.split('-');
    const reversedDateString = `${dateStringArray[2]}-${dateStringArray[1]}-${dateStringArray[0]}`;

    const timeString = dateTimeArray[1];
    const timeArray = timeString.split(':');
    const timePeriodString = dateTimeArray[2];

    let hourString = timeArray[0];
    let hourNumber = parseInt(hourString);
    if (timePeriodString === 'AM') {
      if (hourNumber === 12) {
        hourString = '0' + (hourNumber - 12).toString();
      }
      else if (hourNumber > 10) {
        hourString = hourNumber.toString();
      }
      else if (hourNumber < 10) {
        hourString = '0' + hourNumber.toString();
      }
    }
    else if (timePeriodString === 'PM') {
      if (hourNumber === 12) {
        hourString = hourNumber.toString();
      } else {
        hourNumber = hourNumber + 12;
        hourString = hourNumber.toString();
      }
    }

    const dateTimeString = `${reversedDateString}T${hourString}:${timeArray[1]}`;
    return dateTimeString;

  }

  // convert string to display as an array
  static convertStringToArray(stringTerm: string) {
    if (stringTerm != null) {
      return stringTerm.split(';');
    } else {
      '';
    }

  }


  // Converts Locale String (string number 1,000) back to number (1000)
  static convertLocaleStringToNumber(toLocaleString) {
    let formatted = toLocaleString.replace(',','')
    return parseInt(formatted)
  }

  static commaFormatted(event,numberToFormat) {
    // skip for arrow keys
    if (event.which >= 37 && event.which <= 40) return;

    // format number
    if (numberToFormat) {
      return numberToFormat.replace(/\D/g,"")
        .replace(/\B(?=(\d{3})+(?!\d))/g,",");
    }
  }

  // Check if value is a number

  static numberCheck(numberToCheck) {
    if (numberToCheck.key === 'e' || numberToCheck.key === '+' || numberToCheck.key === '-') {
      return false;
    } else {
      return true;
    }
  }

  // Compare dates to find newer date with time. Returns boolean
  static compareDates(firstDate,secondDate): boolean {
    return new Date(firstDate).getTime() > new Date(secondDate).getTime();
  }

  // return windows location
  static getLocationUrl() {
    return `${window.location.origin}/forgot-password`
  }

  // Error handling
  static errorHandler(error) {

    if (error instanceof HttpErrorResponse) {
      if (error.status === 401) {
        failureToast('Unauthorized');
        this.route.navigate(['/login']);
      }

      if (GeneralUtil.isValidString(error.error.message)) {
        failureToast(error.error.message);
        return;
      }

      failureToast('Network Error');
      return;
    }
    failureToast('Network Error(1)');
    return;
  }
}
