import { Component, ElementRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { AppService } from './app.service';
import { AuthService } from './service/auth/auth.service';
import { HeaderComponent } from './tags/header/header.component';
import { Keepalive } from '@ng-idle/keepalive';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public idleState = 'Not started.';
  public timedOut = false;
  public lastPing?: Date = null;
  public onLogin: boolean;;
  title = 'elsrt-sormas';

  @ViewChild(HeaderComponent) headerApp: HeaderComponent;
  @ViewChild('idleModal') idleModal: ElementRef;
  @ViewChild('close') closeButton: ElementRef;


  constructor(public authService: AuthService, private appService: AppService,
    public router: Router, private idle: Idle, private keepalive: Keepalive) {

    // sets an idle timeout of 30 minutes.
    idle.setIdle(1800);
    // sets a timeout period of 30 minutes, the user will be considered timed out.
    idle.setTimeout(10);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (event.url === '/login') {
          this.onLogin = true;
          idle.stop();
          this.reset();
        } else {
          this.onLogin = false;
          idle.watch();
        }
      })



    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      idle.watch();
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.logout();
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';

      this.idleModal.nativeElement.click();
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will logged out in ' + countdown + ' seconds!';
    });

    // sets the ping interval to 10 minutes
    keepalive.interval(600);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.appService.getUserLoggedIn()
      .subscribe(userLoggedIn => {
        if (userLoggedIn) {
          idle.watch();
          this.timedOut = false;
        } else {
          idle.stop();
          this.timedOut = true;
        }
      })

  }

  reset() {
    this.timedOut = false;
  }

  logout() {
    this.closeButton.nativeElement.click();
    this.authService.resetUserDetails();
    this.appService.setUserLoggedIn(false);
    localStorage.removeItem('TRACKER-WEB-USER-DETAILS-TOKEN');
    localStorage.removeItem('TRACKER-WEB-USER-DETAILS-FULL-NAME');
    localStorage.removeItem('TRACKER-WEB-USER-DETAILS-USERNAME');
    localStorage.removeItem('TRACKER-WEB-USER-DETAILS-FULL-NAME');
    this.router.navigate(['/login']);
  }

  closeNavigation() {
    this.headerApp.hamburger.nativeElement.click();
  }
}
